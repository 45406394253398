table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  border-radius: 40px;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #fff;
  padding: 0.35em;
  border-radius: 4px;
}

table th,
table td {
  padding: 0.625em;
  text-align: left;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.btn-secondary {
  color: white !important;
}

.screenshot-with-bg {
  background-color: #f5f7f8;
  border-radius: 5px;
  padding: 15px;
}

@media screen and (max-width: 600px) {
  table tr {
    background-color: #f8fafa;
  }
  .table-bordered td,
  .table-bordered th,
  .table td,
  .table th {
    border: none;
    cursor: pointer;
  }
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
